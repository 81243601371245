// in src/students.js
import React from 'react';
import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { useDataProvider,SaveContextProvider, FormDataConsumer, SaveButton, Toolbar, useListContext, DateInput, TopToolbar, ArrayInput, SimpleFormIterator, ArrayField, SingleFieldList, ChipField, List, Edit, Create, Datagrid, TextField, EditButton, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, SelectField, AutocompleteInput, isRequired, BooleanInput } from 'react-admin';
import { Box, Typography, CardActions } from '@material-ui/core';
import FlatButton from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import NavigationRefresh from '@material-ui/icons/Refresh';
import { RadioButtonGroupInput, useNotify, useRefresh, useRedirect, ListButton, CreateButton, ShowButton, DeleteButton, BulkDeleteButton } from 'react-admin';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useMediaQuery } from '@material-ui/core';
import MyTab from './mytab.js';
import { TimeInput } from './picker';
import { CheckboxGroupInput, FormTab, TabbedForm } from 'react-admin';

import MuiPhoneNumber from "material-ui-phone-number";
import Signature from './sign.js';
import AppEditButton from './appeditbutton.js';
import DisplaySignature from './dispsignature.js';
import useAnalyticsEventTracker from './analyticsTracker.js';


const 	HOLIDAYS = "09/04/2023,10/09/2023,10/10/2023,10/11/2023,10/12/2023,10/13/2023,11/10/2023,11/22/2023,11/23/2023,11/24/2023,12/18/2023,12/19/2023,12/20/2023,12/21/2023,12/22/2023,12/25/2023,12/26/2023,12/27/2023,12/28/2023,12/29/2023,01/01/2024,01/15/2024,02/19/2024,03/11/2024,03/12/2024,03/13/2024,03/14/2024,03/15/2024,03/29/2024,04/19/2024,05/27/2024,06/19/2024";
const validateName = [required(), minLength(2), maxLength(45)];
const validateEmail = [required(), email()];
const validateDate = [required(), regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateDateOpt = [regex(/[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]/, "Must be valid date mm/dd/yyyy")];
const validateSex = [required()];
const validateState = [required(), regex(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/, "Must be valid US State (use 2 Chars)")];
const validateStateOpt = [regex(/^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/, "Must be valid US State (use 2 Chars)")];
const validateZip = [required(), regex(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be valid zipcode")];
const validateZipOpt = [regex(/^[0-9]{5}(?:-[0-9]{4})?$/, "Must be valid zipcode")];
const validateDob = [required(),checkDob]
const validateEnrollmentDate = [required(),checkEDt]
const validateParentData = (values) => {
    return (values) => {
        if (values != undefined && values.length == 2) {
            return undefined;
        }
        return "Please submit both Parents Information";
    };
}
const validateTourPrecond = (values) => {
    // console.log("val", values, "done")
    if (values == undefined) {
        // console.log("error")
        return "Please schedule a tour as you have selected that is a precondition for your application approval";
    } else {
        return undefined;
    }

}
function checkDob(value) {
    // console.log("Dt ",value);
    var today = new Date();
    var dt = new Date(value);
    const yearDiff = today.getFullYear() - dt.getFullYear();

    // Get the number of months in the first year.
    const firstYearMonths = dt.getMonth() + 1;

    // Get the number of months in the second year.
    const secondYearMonths = today.getMonth();

    // Calculate the total number of months between the two dates.
    const diffInMonths = yearDiff * 12 - firstYearMonths + secondYearMonths;
    // console.log("Duiff mnths",diffInMonths,yearDiff,firstYearMonths,secondYearMonths);

    if (diffInMonths < 9) {
        return "Age of child should be 9 months for applying and 18+ months for admission";
    }
}

function checkEDt(value) {
    //date has to be in future and can not be holiday or weekend
    const today = new Date();    
    var dt = new Date(value);

    // Check if the date is in the future
    if (dt <= today) {
        return "Enrollment Date has to be in future";
    }
    const dayOfWeek = dt.getDay();
    // console.log("DOW",dayOfWeek);
    if (dayOfWeek === 6 || dayOfWeek === 5) {
        return "Enrollment cannot start on weekend";
    }
    // console.log("DT",convertDateFormat(value));
    if (HOLIDAYS.includes(convertDateFormat(value))) {
        return "Enrollment Date cannot be a Holiday";
    }

}

function convertDateFormat(inputDate) {
    // Split the input date string using "/"
    const dateParts = inputDate.split('-');

    // Rearrange the parts to match "yyyy-mm-dd" format
    const dd = dateParts[2];
    const yyyy = dateParts[0]; 
    const mm = dateParts[1]; 

    // Join the parts using "-"
    const outputDate = `${mm}/${dd}/${yyyy}`;

    return outputDate;
}

// console.log("Env:" + process.env.NODE_ENV)
const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[-]+/g, '');
    let x;
    if (newNum.length <= 3) {
        x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6)
    } else {
        x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6) + "-" + newNum.slice(6, 10)
    }
    return x;
};


const validatePhone = [required(), regex(/[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/), "Valid Phone number is required"];
const validatePhoneOpt = [regex(/[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]/), "Valid Phone number is required"];
const uid = localStorage.getItem('userID');


const ActionsRefresh = ({
    currentSort,
    className,
    resource,
    filters,
    displayedFilters,
    exporter, // you can hide ExportButton if exporter = (null || false)
    filterValues,
    permanentFilter,
    hasCreate = true, // you can hide CreateButton if hasCreate = false
    selectedIds,
    onUnselectItems,
    showFilter,
    maxResults,
    total,
    ...rest
}) => (
    <CardActions>
        <CreateButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            // filter={{ ...filterValues, ...permanentFilter }}
            exporter={exporter}
            maxResults={maxResults}
        />
    </CardActions>
);

const Empty = () => {
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h6" paragraph>
                Welcome to LAMS Application submission
            </Typography>
            <Typography variant="body1">
                Please click <CreateButton basePath={basePath} /> to Submit New Application
            </Typography>

        </Box>
    );
};

export const LamsApplicationList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const gaEventTracker = useAnalyticsEventTracker('LAMS Applications');
    gaEventTracker('LAMS Application List');
    const uid = localStorage.getItem('userID');
    if (permissions !== undefined && permissions.role !== null && permissions.role === 'user') {
        return (
            <List title={<span><MyTab />LAMS Applications Submitted</span>} empty={< Empty />} {...props} actions={< ActionsRefresh {...props} />} sort={{ field: 'Sname', order: 'ASC' }} /* filter= { { userid: uid }}*/ bulkActionButtons={false}>
                {/* <span>Welcome to LAMS Application submission, You have no application click Create button to submit one</span> */}
                {isSmall ? (
                    <Datagrid>
                        <TextField label="Child Name" source="Child.Name" disabled />
                        <TextField label="Status" source="Status" />
                        <TextField label="Date Submitted" source="DtCreated" disabled />
                    </Datagrid>
                ) : (
                    <Datagrid>
                        <TextField label="Child Name" source="Child.Name" disabled />
                        <TextField label="Status" source="Status" />
                        <TextField label="Date Submitted" source="DtCreated" disabled />
                        <AppEditButton />
                    </Datagrid>
                )
                }

            </List >
        );
    } else {
        return (<span>User has No Access...</span>)
    }
};

const LamsApplicationTitle = ({ record }) => {
    return <span>LamsApplication {record ? `"${record.id}"` : ''}</span>;
};

var primaryPrg = false;
const handlePrgSel = event => {
    // console.log("IN HERE for Potty trg", event);
    if (event === "Primary") {
        primaryPrg = true;
    } else {
        primaryPrg = false;
    }
}
var tourPrereq = false;
const handleTourPrecond = event => {
    // console.log("IN HERE for Tour Pre cond", event.target.value);
    if (event.target.value === "Y") {
        tourPrereq = true;
    } else {
        tourPrereq = false;
    }
}

export const LamsApplicationCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const [saving, setSaving] = useState(false);
    const dataProvider = useDataProvider();
    const fetchURLAppln = process.env.REACT_APP_GO_CONNECT_URL + "/Applications"
    const fetchURLTDtls = process.env.REACT_APP_GO_CONNECT_URL + "/tours/TourDetails"
    const fetchURLTDtTm = process.env.REACT_APP_GO_CONNECT_URL + "/TourDateTimes"
    const validateEnrollmentDatewithTour = [required(),checkEDtwithTour]
    const validateAgeForProgram = [required(),checkAgeForProgram]
    const tourpcRef = useRef();
    const tourDtTmRef = useRef();
    const dobRef = useRef();

    function checkAgeForProgram(prg) {
        var today = new Date();
        var dt = new Date(dobRef.current.value);
        const yearDiff = today.getFullYear() - dt.getFullYear();
    
        // Get the number of months in the first year.
        const firstYearMonths = dt.getMonth() + 1;
    
        // Get the number of months in the second year.
        const secondYearMonths = today.getMonth();
    
        // Calculate the total number of months between the two dates.
        const diffInMonths = yearDiff * 12 - firstYearMonths + secondYearMonths;
        // console.log("Duiff mnths",diffInMonths,yearDiff,firstYearMonths,secondYearMonths);
        // console.log("Dt for age prg check",prg,dobRef.current.value,diffInMonths);
        if (prg === "Toddler" && diffInMonths >=31 ) {
            return "Age of child should be under 2years 6 months for toddler program";
        }
    }

    function checkEDtwithTour (value) {
        //date has to be in future and can not be holiday or weekend
        const today = new Date();    
        var dt = new Date(value);
    
        // Check if the date is in the future
        if (dt <= today) {
            return "Enrollment Date has to be in future";
        }
        const dayOfWeek = dt.getDay();
        // console.log("DOW",dayOfWeek);
        if (dayOfWeek === 6 || dayOfWeek === 5) {
            return "Enrollment cannot start on weekend";
        }
        // console.log("DT",convertDateFormat(value));
        if (HOLIDAYS.includes(convertDateFormat(value))) {
            return "Enrollment Date cannot be a Holiday";
        }

        // console.log(tourpcRef, tourDtTmRef);
        if (tourDtTmRef.current != null) {
            const tourPC = tourpcRef.current.value;
            const tourDtTm = tourDtTmRef.current.value;
            if (tourPC != "" && tourDtTm != undefined) {
                // console.log("Validating", dt, tourPC, tourDtTm);
                //check if tour precond is Y then enrollment date has to be after tour date
                if (tourPC == "Y") {
                    const tourDt = tourDtTm.split(" / ");
                    const tDt = new Date(tourDt);
                    if (dt <= tDt) {
                        return "For Tour as Precondition for Approval, child's Enrollment Date has to be after the Tour has been taken";
                    }

                } 
            } 
        }
    
    }


    const handleSave = (values) => {
        // console.log(saving);
        if (saving) {
            notify("Application is already being submitted.", "error");
        return;
        }
        // console.log("Handle Save", values);
        setSaving(true);
        var appid, userid
       
        var bdy = JSON.stringify(values);
        var applnResp;
        const token = localStorage.getItem('token');
        var hdrs = new Headers({
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
            "Content-Length": JSON.stringify(bdy).length
        })
        fetch(fetchURLAppln, { method: 'POST', body: bdy, headers: hdrs })
            .then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        // console.log("Appln",response, json);
                        if (json != "" && values.DateTime != undefined) {
                            try {
                            //success call tours and update app with TID
                            applnResp = json;
                            var tReq = { ParentName: values.Parents[0].Name, StudentName: values.Child.Name, Email: values.Parents[0].Email, Phone: values.Parents[0].Phone, DateTime: values.DateTime };
                            bdy = JSON.stringify(tReq);
                            hdrs = new Headers({
                                "Content-Type": "application/json",
                                "Authorization": "Bearer " + token,
                                "Content-Length": JSON.stringify(bdy).length
                            })
                            fetch(fetchURLTDtls, { method: 'POST', body: bdy, headers: hdrs })
                                .then(response => {
                                    // console.log("Tout Dtals",response, json);
                                    if (response.ok) {
                                        response.json().then(json => {
                                            // console.log(json);
                                            if (json != "") {
                                                applnResp.TID = json.id;
                                                bdy = JSON.stringify(applnResp);
                                                hdrs = new Headers({
                                                    "Content-Type": "application/json",
                                                    "Authorization": "Bearer " + token,
                                                    "Content-Length": JSON.stringify(bdy).length,
                                                    "Email":"no"
                                                })
                                                fetch(fetchURLAppln + "/" + applnResp.id, { method: 'PUT', body: bdy, headers: hdrs })
                                                    .then(response => {
                                                        if (response.ok) {
                                                            notify("Application Submitted and Tour created", "info")
                                                            redirect("/Applications")
                                                        }
                                                    });
                                                //
                                            }

                                        });
                                    } else {
                                        response.json().then(json => {
                                            // console.log(response, json);
                                            if (json != "") {
                                                notify("Error:" + json.message, "error");
                    
                                            } else {
                                                notify("Unable to create tour", "error");
                                            }
                                        });
                                    }
                                });
                            } catch (e) {
                                console.error(e);
                                notify('Application Submitted, Unable to create tour', 'warning')
                                redirect("/Applications")
                            }
                        } else {
                            notify("Application Submitted", "info")
                            redirect("/Applications")
                        }

                    });
                } else if (response.status === 401) {
                    notify("Unauthorized, please login...", "error");
                } else if (response.status === 500) {
                    notify("Internal Error, please try again or report this error to LAMS", "error");
                } else {
                    response.json().then(json => {
                        // console.log(response, json);
                        if (json != "") {
                            notify("Unable to create application " + json.message, "error");

                        } else {
                            notify("Error generating Application", "error");
                        }
                    });
                }
                setSaving(false);

            })
            .catch((e) => {
                console.error(e);
                notify('Error: Unable to create application', 'error');
            });


    };
    // const saveContext = useMemo(() => ({
    //     save: handleSave,
    //     saving
    // }), [saving, handleSave]);
    

    const onSuccess = () => {
        notify(`Your application has been submitted`)
        redirect('/Applications');
        refresh();
    };
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const gaEventTracker = useAnalyticsEventTracker('LAMS Applications');
    gaEventTracker('LAMS Application Create');

    const uid = localStorage.getItem('userID');
    // console.log("LamsApplication for user ", uid);
    const postDefaultValue = () => ({ UserID: uid });
    const [TourDateTimes, setTourDateTimes] = useState([]);
    useEffect(() => {
        const fetchTourDateTimes = async () => {
            try {
                const token = localStorage.getItem('token');
                const hdrs = new Headers({
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                })
                const params = new Object();
                const bdy = JSON.stringify(params);
                fetch(fetchURLTDtTm, { method: 'GET', headers: hdrs })
                    .then(response => {
                        if (response.ok) {
                            response.json().then(json => {
                                if (json != "") {
                                    var dates = json.map((date, index) => {
                                        return {
                                            id: date, 
                                            name: date
                                        };
                                    });
                                    // console.log(dates);
                                    setTourDateTimes(dates)
                                } else {
                                    
                                }
                            });
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        //ignore user should be able to submit application even if tour is not working
                    });
  
            } catch (error) {
                console.error('Error fetching TourDateTimes:', error);
            }
        };
        fetchTourDateTimes();
    }, [dataProvider]);

    return (
        <Create onSuccess={onSuccess} {...props} title={<span><MyTab />Submit a New LAMS Enrollment Application form</span>}>
            {isSmall ? (
                <TabbedForm initialValues={postDefaultValue} save={handleSave}>
                {/* <FormTab label="Child's Information" > */}<FormTab label="">
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Child's Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }} >
                            <Box display="flex">
                                <Box>
                                    <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                                </Box>
                                <Box ml="2em">
                                    <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box>
                                    <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={validateDob} inputRef={dobRef} />
                                </Box>
                                <Box ml="2em">
                                    <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box >
                                    <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                        { id: 'M', name: 'M' },
                                        { id: 'F', name: 'F' },
                                        { id: 'O', name: 'Other' }
                                    ]} />
                                </Box>
                                <Box ml="2em" >
                                    <TextInput
                                        parse={phoneNumberFormat}
                                        label="Child's Contact Phone"
                                        source="Child.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                            <Box >
                                <Box>
                                    <Box>
                                        <b>Child’s Home Address </b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                        <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                        <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                                    </Box>
                                </Box>
                                <Box >
                                    <Box>
                                        <b>Child’s Mailing Address, if different from above </b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                        <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                        <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                                    </Box>
                                </Box>
                                <Box>
                                    <Box>
                                        <b>Child's Siblings? </b>
                                    </Box>
                                    <Box display="flex">
                                        <Box ml="5em" mr="12em">
                                            <b>First and Last Name </b>
                                        </Box>
                                        <Box mr="6em">
                                            <b>Age </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Gender </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Is Currently Enrolled at LAMS? </b>
                                        </Box>
                                    </Box>
                                    <ArrayInput label="" source="Siblings" >
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({ getSource, scopedFormData }) => {
                                                    return (
                                                        <Box display="flex">
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                    { id: 'M', name: 'M' },
                                                                    { id: 'F', name: 'F' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                                    { id: 'Y', name: 'Y' },
                                                                    { id: 'N', name: 'N' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                                <Box>
                                    <Box>
                                        <b>Whom does the child live with and the household members (parents, relatives, etc.)? </b>
                                    </Box>
                                    <Box display="flex">
                                        <Box ml="5em" mr="12em">
                                            <b>First and Last Name </b>
                                        </Box>
                                        <Box mr="7em">
                                            <b>Relationship to Child</b>
                                        </Box>
                                        <Box mr="6em">
                                            <b>Age </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Gender </b>
                                        </Box>
                                    </Box>
                                    <ArrayInput label="" source="HHmembers" validate={required()}>
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({ getSource, scopedFormData }) => {
                                                    return (
                                                        <Box display="flex">
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                    { id: 'M', name: 'M' },
                                                                    { id: 'F', name: 'F' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                            <Box >
                                <Box >
                                    <b>What languages does the child speak at home? </b>
                                </Box>
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                        { id: 'English', name: 'English' },
                                        { id: 'Spanish', name: 'Spanish' },
                                        { id: 'Other', name: 'Other' }
                                    ]} validate={required()} />
                                    <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                                </Box>
                            </Box>
                            <Box >
                                <Box >
                                    <b><u>Child’s Doctor:</u></b>
                                </Box>
                                <Box>
                                    <Box>
                                        <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                                    </Box>
                                    <Box>
                                        <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                                    </Box>
                                    <Box >
                                        <b><u>Address:</u></b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                        <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                        <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                                    </Box>
                                    <Box>
                                        <TextInput
                                            parse={phoneNumberFormat}
                                            label="Phone"
                                            source="Child.Dr.Phone"
                                            validate={validatePhone}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box >
                                <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                                <Box>
                                    <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                                </Box>
                            </Box>
                        </Box>
                        {/* </FormTab> */}
                    </AccordionDetails>
                </Accordion>
                {/* <FormTab label="Parent/Guardian Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Parent/Guardian Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }} >
                            <Box >
                                <span><b>Parent / Guardian:</b></span>
                            </Box>
                            <ArrayInput label="" source="Parents" validate={[required(), validateParentData()]}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box style={{ width: "100%" }}>
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }}
                                                                parse={phoneNumberFormat}
                                                                label="Contact Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhone}
                                                            />
                                                        </Box>
                                                        <Box >
                                                            <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                        </Box>
                                                    </Box>
                                                    <Box >
                                                        <Box >
                                                            <span><b>Address if different from Child's home address:</b></span>
                                                        </Box>
                                                        <Box display="flex" >
                                                            <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                        </Box>
                                                    </Box>
                                                    <Box >
                                                        <span><b>Work Info:</b></span>
                                                    </Box>
                                                    <Box >
                                                        <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                        <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                        <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                        <Box >
                                                            <span><b>Company Address:</b></span>
                                                        </Box>
                                                        <Box display="flex">
                                                            <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="Personal Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Personal Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }} >

                            <Box >
                                <span><b>Child’s interests and favorite activities:</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                            </Box>
                            <Box >
                                <span><b>Characteristic Behavior (check all that apply):</b></span>
                                <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                                    { id: 'shy', name: 'Shy' },
                                    { id: 'calm', name: 'Calm' },
                                    { id: 'cheerful', name: 'Cheerful' },
                                    { id: 'aggressive', name: 'Aggressive' },
                                    { id: 'whining', name: 'Whining' },
                                    { id: 'friendly', name: 'Friendly' },
                                    { id: 'excitable', name: 'Excitable' },
                                    { id: 'fearful', name: 'Fearful' },
                                    { id: 'cooperative', name: 'Cooperative' }
                                ]} validate={required()} />
                            </Box>
                            <Box >
                                <span><b>Any fears? (History and manifestations):</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                            </Box>
                            <Box >
                                <span><b>Types of home discipline most frequently used:</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                            </Box>
                            <Box>
                                <Box >
                                    <span><b>Emergency Contact</b></span>
                                </Box>
                                <Box>
                                    <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                                </Box>
                                <Box display="flex">
                                    <Box mr="14em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="8em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Contact Phone# </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="EmergContacts" validate={required()}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput
                                                                style={{ width: 150 }} parse={phoneNumberFormat}
                                                                label="Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhone}
                                                            />
                                                        </Box>

                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <Box>
                                <Box >
                                    <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                                </Box>
                                <Box>
                                    <b>Who will be the main adult to drop-off/pick-up the child? </b>
                                </Box>
                                <Box display="flex">
                                    <Box mr="14em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="8em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Contact Phone# </b>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box mr="4em">
                                        <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                                    </Box>
                                    <Box mr="4em">
                                        <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                                    </Box>
                                    <Box mr="4em">
                                        <TextInput
                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                            label="Phone"
                                            source="MainAuth.Phone"
                                            validate={validatePhone}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                                </Box>
                                <ArrayInput label="" source="Oauths" isRequired={false} >
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput
                                                                style={{ width: 150 }} parse={phoneNumberFormat}
                                                                label="Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhoneOpt}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="Enrollment Program Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Enrollment Program Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box >
                                <span><b>How did you hear about LAMS?</b></span>
                                <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                                    { id: 'Adwebsite', name: 'Website' }
                                ]} />
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                        { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                                    ]} />
                                    <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                                </Box>
                                <Box display="flex">

                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                        { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                                    ]} />
                                    <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                                    <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                                </Box>
                                <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                                    { id: 'Adfndrel', name: 'From a Friend/Relative' }
                                ]} />
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                        { id: 'Adother', name: 'Other' }
                                    ]} />
                                    <TextInput label="Specify" source="Mkting.OtherDtls" />
                                </Box>
                            </Box>
                            <Box >
                                <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                                <Box display="flex">
                                    <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                        { id: '2', name: '2 Years' },
                                        { id: '3', name: '3 Years' },
                                        { id: 'other', name: 'Other' }
                                    ]} validate={required()} />
                                    <TextInput label="Specify" source="Intent.Other" />
                                </Box>
                                <Box>
                                    <span><b>When is the desired enrollment (start) date? [School is closed for month of July for summer vacation, no fee is charged]</b></span><br></br>
                                    <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={validateEnrollmentDatewithTour} />
                                </Box>
                            </Box>
                            <Box >
                                <span><b>Program Selection: </b></span><br></br>
                                <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                    { id: 'Toddler', name: 'Toddler Program' },
                                    { id: 'Primary', name: 'Primary Program' }
                                ]} onChange={handlePrgSel} validate={validateAgeForProgram} />
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                            { id: 'N', name: 'N' },
                                            { id: 'Y', name: 'Y' },
                                            // { id: 'T', name: 'InTraining' }
                                        ]} {...rest} validate={required()} />
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                            <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                                </FormDataConsumer>
                                <br></br>
                                <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                                    { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                    { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                    { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                                ]} validate={required()} />
                            </Box>
                            <Box>
                                <span><b>Approval Acceptance: </b></span><br></br>
                                <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                                <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                    { id: 'Y', name: 'Y' },
                                    { id: 'N', name: 'N' },
                                ]} onChange={handleTourPrecond} validate={required()} inputRef={tourpcRef} />
                            </Box>
                            <Box>
                                <span><b>You may submit a request to schedule a tour</b><br></br></span>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => tourPrereq &&
                                        <SelectInput label="Available Dates & Time" source="DateTime" choices={TourDateTimes} validate={validateTourPrecond} inputRef={tourDtTmRef} />
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => !tourPrereq &&
                                        <SelectInput label="Available Dates & Time" source="DateTime" allowEmpty choices={TourDateTimes} optionText="name" optionValue="id" />
                                    }
                                </FormDataConsumer>
                            </Box>

                            <Box >
                                <span><b>Does your child have previous school experience?</b></span>
                                <Box display="flex">
                                    <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                                    <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                                    <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                                    <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />

                                </Box>
                            </Box>
                            <Box >
                                <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                                <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                            </Box>
                            <Box >
                                <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                                <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="General Release Form"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>General Release Form</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>

                            <Box >
                                <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                                    <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                        { id: 'Y', name: 'Yes' },
                                        { id: 'N', name: 'No' }

                                    ]} validate={required()} />
                                    <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                                    <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                                    <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                                    <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                                    <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                                    <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                                    <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                                    <li>We understand balances due must be paid before evaluations can be released.</li>
                                    <li>Fee once paid is not refundable under any circumstances.</li>
                                    <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                                </ol>
                            </Box>
                            <Box>
                                <span><b>Parent’s/Guardian’s Approval</b></span>
                                <Box>
                                    <span><b>Parent/Guardian #1</b></span><br></br>
                                    <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                                    <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                                    <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                                    <Box style={{ width: "100%" }}>
                                        <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                        <Signature source="GenRelease.PApp1.Signature" validate={required()} />
                                    </Box>

                                </Box>
                                <Box>
                                    <span><b>Parent/Guardian #2</b></span><br></br>
                                    <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                                    <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                                    <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                                    <Box style={{ width: "100%" }}>
                                        <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                        <Signature source="GenRelease.PApp2.Signature" validate={required()} />
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </AccordionDetails>
                </Accordion>
                </FormTab>
            </TabbedForm >
            ) : (        
            <TabbedForm initialValues={postDefaultValue} save={handleSave}>
                <FormTab label="Child's Information" >
                        <Box style={{ width: "100%" }} >
                            <Box display="flex">
                                <Box>
                                    <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                                </Box>
                                <Box ml="2em">
                                    <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box>
                                    <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={validateDob} inputRef={dobRef} />
                                </Box>
                                <Box ml="2em">
                                    <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box >
                                    <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                        { id: 'M', name: 'M' },
                                        { id: 'F', name: 'F' },
                                        { id: 'O', name: 'Other' }
                                    ]} />
                                </Box>
                                <Box ml="2em" >
                                    <TextInput
                                        parse={phoneNumberFormat}
                                        label="Child's Contact Phone"
                                        source="Child.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                            <Box >
                                <Box>
                                    <Box>
                                        <b>Child’s Home Address </b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                        <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                        <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                                    </Box>
                                </Box>
                                <Box >
                                    <Box>
                                        <b>Child’s Mailing Address, if different from above </b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                        <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                        <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                                    </Box>
                                </Box>
                                <Box>
                                    <Box>
                                        <b>Child's Siblings? </b>
                                    </Box>
                                    <Box display="flex">
                                        <Box ml="5em" mr="12em">
                                            <b>First and Last Name </b>
                                        </Box>
                                        <Box mr="6em">
                                            <b>Age </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Gender </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Is Currently Enrolled at LAMS? </b>
                                        </Box>
                                    </Box>
                                    <ArrayInput label="" source="Siblings" >
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({ getSource, scopedFormData }) => {
                                                    return (
                                                        <Box display="flex">
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                    { id: 'M', name: 'M' },
                                                                    { id: 'F', name: 'F' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                                    { id: 'Y', name: 'Y' },
                                                                    { id: 'N', name: 'N' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                                <Box>
                                    <Box>
                                        <b>Whom does the child live with and the household members (parents, relatives, etc.)? </b>
                                    </Box>
                                    <Box display="flex">
                                        <Box ml="5em" mr="12em">
                                            <b>First and Last Name </b>
                                        </Box>
                                        <Box mr="7em">
                                            <b>Relationship to Child</b>
                                        </Box>
                                        <Box mr="6em">
                                            <b>Age </b>
                                        </Box>
                                        <Box mr="4em">
                                            <b>Gender </b>
                                        </Box>
                                    </Box>
                                    <ArrayInput label="" source="HHmembers" validate={required()}>
                                        <SimpleFormIterator>
                                            <FormDataConsumer>
                                                {({ getSource, scopedFormData }) => {
                                                    return (
                                                        <Box display="flex">
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                            </Box>
                                                            <Box mr="4em">
                                                                <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                    { id: 'M', name: 'M' },
                                                                    { id: 'F', name: 'F' },
                                                                ]} validate={required()} />
                                                            </Box>
                                                        </Box>
                                                    );
                                                }}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Box>
                            </Box>
                            <Box >
                                <Box >
                                    <b>What languages does the child speak at home? </b>
                                </Box>
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                        { id: 'English', name: 'English' },
                                        { id: 'Spanish', name: 'Spanish' },
                                        { id: 'Other', name: 'Other' }
                                    ]} validate={required()} />
                                    <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                                </Box>
                            </Box>
                            <Box >
                                <Box >
                                    <b><u>Child’s Doctor:</u></b>
                                </Box>
                                <Box>
                                    <Box>
                                        <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                                    </Box>
                                    <Box>
                                        <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                                    </Box>
                                    <Box >
                                        <b><u>Address:</u></b>
                                    </Box>
                                    <Box display="flex">
                                        <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                        <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                        <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                        <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                        <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                                    </Box>
                                    <Box>
                                        <TextInput
                                            parse={phoneNumberFormat}
                                            label="Phone"
                                            source="Child.Dr.Phone"
                                            validate={validatePhone}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box >
                                <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                                <Box>
                                    <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                                </Box>
                            </Box>
                        </Box>
                        </FormTab>
                <FormTab label="Parent/Guardian Information">
                        <Box style={{ width: "100%" }} >
                            <Box >
                                <span><b>Parent / Guardian:</b></span>
                            </Box>
                            <ArrayInput label="" source="Parents" validate={[required(), validateParentData()]}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box style={{ width: "100%" }}>
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }}
                                                                parse={phoneNumberFormat}
                                                                label="Contact Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhone}
                                                            />
                                                        </Box>
                                                        <Box >
                                                            <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                        </Box>
                                                    </Box>
                                                    <Box >
                                                        <Box >
                                                            <span><b>Address if different from Child's home address:</b></span>
                                                        </Box>
                                                        <Box display="flex" >
                                                            <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                        </Box>
                                                    </Box>
                                                    <Box >
                                                        <span><b>Work Info:</b></span>
                                                    </Box>
                                                    <Box >
                                                        <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                        <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                        <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                        <Box >
                                                            <span><b>Company Address:</b></span>
                                                        </Box>
                                                        <Box display="flex">
                                                            <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                            <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                </FormTab>
                <FormTab label="Personal Information">
                        <Box style={{ width: "100%" }} >

                            <Box >
                                <span><b>Child’s interests and favorite activities:</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                            </Box>
                            <Box >
                                <span><b>Characteristic Behavior (check all that apply):</b></span>
                                <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                                    { id: 'shy', name: 'Shy' },
                                    { id: 'calm', name: 'Calm' },
                                    { id: 'cheerful', name: 'Cheerful' },
                                    { id: 'aggressive', name: 'Aggressive' },
                                    { id: 'whining', name: 'Whining' },
                                    { id: 'friendly', name: 'Friendly' },
                                    { id: 'excitable', name: 'Excitable' },
                                    { id: 'fearful', name: 'Fearful' },
                                    { id: 'cooperative', name: 'Cooperative' }
                                ]} validate={required()} />
                            </Box>
                            <Box >
                                <span><b>Any fears? (History and manifestations):</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                            </Box>
                            <Box >
                                <span><b>Types of home discipline most frequently used:</b></span><br></br>
                                <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                            </Box>
                            <Box>
                                <Box >
                                    <span><b>Emergency Contact</b></span>
                                </Box>
                                <Box>
                                    <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                                </Box>
                                <Box display="flex">
                                    <Box mr="14em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="8em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Contact Phone# </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="EmergContacts" validate={required()}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput
                                                                style={{ width: 150 }} parse={phoneNumberFormat}
                                                                label="Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhone}
                                                            />
                                                        </Box>

                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <Box>
                                <Box >
                                    <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                                </Box>
                                <Box>
                                    <b>Who will be the main adult to drop-off/pick-up the child? </b>
                                </Box>
                                <Box display="flex">
                                    <Box mr="14em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="8em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Contact Phone# </b>
                                    </Box>
                                </Box>
                                <Box display="flex">
                                    <Box mr="4em">
                                        <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                                    </Box>
                                    <Box mr="4em">
                                        <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                                    </Box>
                                    <Box mr="4em">
                                        <TextInput
                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                            label="Phone"
                                            source="MainAuth.Phone"
                                            validate={validatePhone}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                                </Box>
                                <ArrayInput label="" source="Oauths" isRequired={false} >
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput
                                                                style={{ width: 150 }} parse={phoneNumberFormat}
                                                                label="Phone"
                                                                source={getSource('Phone')}
                                                                validate={validatePhoneOpt}
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                </FormTab>
                <FormTab label="Enrollment Program Information">
                        <Box style={{ width: "100%" }}>
                            <Box >
                                <span><b>How did you hear about LAMS?</b></span>
                                <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                                    { id: 'Adwebsite', name: 'Website' }
                                ]} />
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                        { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                                    ]} />
                                    <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                                </Box>
                                <Box display="flex">

                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                        { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                                    ]} />
                                    <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                                    <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                                </Box>
                                <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                                    { id: 'Adfndrel', name: 'From a Friend/Relative' }
                                ]} />
                                <Box display="flex">
                                    <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                        { id: 'Adother', name: 'Other' }
                                    ]} />
                                    <TextInput label="Specify" source="Mkting.OtherDtls" />
                                </Box>
                            </Box>
                            <Box >
                                <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                                <Box display="flex">
                                    <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                        { id: '2', name: '2 Years' },
                                        { id: '3', name: '3 Years' },
                                        { id: 'other', name: 'Other' }
                                    ]} validate={required()} />
                                    <TextInput label="Specify" source="Intent.Other" />
                                </Box>
                                <Box>
                                    <span><b>When is the desired enrollment (start) date? [School is closed for month of July for summer vacation, no fee is charged]</b></span><br></br>
                                    <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={validateEnrollmentDatewithTour} />
                                </Box>
                            </Box>
                            <Box >
                                <span><b>Program Selection: </b></span><br></br>
                                <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                    { id: 'Toddler', name: 'Toddler Program' },
                                    { id: 'Primary', name: 'Primary Program' }
                                ]} onChange={handlePrgSel} validate={validateAgeForProgram} />
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                            { id: 'N', name: 'N' },
                                            { id: 'Y', name: 'Y' },
                                            // { id: 'T', name: 'InTraining' }
                                        ]} {...rest} validate={required()} />
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                            <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                                </FormDataConsumer>
                                <br></br>
                                <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                                    { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                    { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                    { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                                ]} validate={required()} />
                            </Box>
                            <Box>
                                <span><b>Approval Acceptance: </b></span><br></br>
                                <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                                <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                    { id: 'Y', name: 'Y' },
                                    { id: 'N', name: 'N' },
                                ]} onChange={handleTourPrecond} validate={required()} inputRef={tourpcRef} />
                            </Box>
                            <Box>
                                <span><b>You may submit a request to schedule a tour</b><br></br></span>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => tourPrereq &&
                                        <SelectInput label="Available Dates & Time" source="DateTime" choices={TourDateTimes} validate={validateTourPrecond} inputRef={tourDtTmRef} />
                                    }
                                </FormDataConsumer>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => !tourPrereq &&
                                        <SelectInput label="Available Dates & Time" source="DateTime" allowEmpty choices={TourDateTimes} optionText="name" optionValue="id" />
                                    }
                                </FormDataConsumer>
                            </Box>

                            <Box >
                                <span><b>Does your child have previous school experience?</b></span>
                                <Box display="flex">
                                    <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                                    <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                                    <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                                    <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />

                                </Box>
                            </Box>
                            <Box >
                                <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                                <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                            </Box>
                            <Box >
                                <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                                <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                            </Box>
                        </Box>
                </FormTab>
                <FormTab label="General Release Form">
                        <Box style={{ width: "100%" }}>

                            <Box >
                                <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                                    <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                        { id: 'Y', name: 'Yes' },
                                        { id: 'N', name: 'No' }

                                    ]} validate={required()} />
                                    <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                                    <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                                    <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                                    <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                                    <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                                    <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                                    <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                                    <li>We understand balances due must be paid before evaluations can be released.</li>
                                    <li>Fee once paid is not refundable under any circumstances.</li>
                                    <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                                </ol>
                            </Box>
                            <Box>
                                <span><b>Parent’s/Guardian’s Approval</b></span>
                                <Box>
                                    <span><b>Parent/Guardian #1</b></span><br></br>
                                    <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                                    <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                                    <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                                    <Box style={{ width: "100%" }}>
                                        <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                        <Signature source="GenRelease.PApp1.Signature" validate={required()} />
                                    </Box>

                                </Box>
                                <Box>
                                    <span><b>Parent/Guardian #2</b></span><br></br>
                                    <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                                    <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                                    <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                                    <Box style={{ width: "100%" }}>
                                        <span style={{ color: "red", "font-weight": "bold" }}>Signature:(REQUIRED)</span>
                                        <Signature source="GenRelease.PApp2.Signature" validate={required()} />
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                </FormTab>
            </TabbedForm >
            )}  
        </Create >
    );
}

export const LamsApplicationEdit = (props) => { //Never used, users cannot edit application once submitted so this might be really outdated as compared to Create
    const gaEventTracker = useAnalyticsEventTracker('LAMS Applications');
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    gaEventTracker('LAMS Application Edit');
    useEffect(() => {
        var radios = document.getElementsByName('Prg.Name');
        // console.log("In useeffect", radios);
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio
                // console.log("Radio Value", radios[i].value);
                if (radios[i].value === "Primary") {
                    primaryPrg = true;
                } else {
                    primaryPrg = false;
                }
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }

    }, []);
    return (
        <Edit title={<LamsApplicationTitle />} {...props}>
            {isSmall ? (
            <TabbedForm>
                {/* <FormTab label="Child's Information" > */}
                <FormTab label="">
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Child's Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box style={{ width: "100%" }} >
                        <Box display="flex">
                            <Box>
                                <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box>
                                <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={validateDob} disabled/>
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box >
                                <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                    { id: 'M', name: 'M' },
                                    { id: 'F', name: 'F' },
                                    { id: 'O', name: 'Other' }
                                ]} />
                            </Box>
                            <Box ml="2em" >
                                <TextInput
                                    parse={phoneNumberFormat}
                                    label="Child's Contact Phone"
                                    source="Child.Phone"
                                    validate={validatePhone}
                                />
                            </Box>
                        </Box>
                        <Box >
                            <Box>
                                <Box>
                                    <b>Child’s Home Address </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                                </Box>
                            </Box>
                            <Box >
                                <Box>
                                    <b>Child’s Mailing Address, if different from above </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                    <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                    <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Child's Siblings? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Is Currently Enrolled at LAMS? </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="Siblings">
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                                { id: 'Y', name: 'Y' },
                                                                { id: 'N', name: 'N' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Whom does the child live with and the household members (parents, siblings, relatives, etc.)? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="7em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="HHmembers" validate={required()}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b>What languages does the child speak at home? </b>
                            </Box>
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                    { id: 'Other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b><u>Child’s Doctor:</u></b>
                            </Box>
                            <Box>
                                <Box>
                                    <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                                </Box>
                                <Box>
                                    <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                                </Box>
                                <Box >
                                    <b><u>Address:</u></b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                                </Box>
                                <Box>
                                    <TextInput
                                        parse={phoneNumberFormat}
                                        label="Phone"
                                        source="Child.Dr.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                            <Box>
                                <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                            </Box>
                        </Box>
                    </Box>
                    </AccordionDetails>
                    </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="Parent/Guardian Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Parent/Guardian Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box style={{ width: "100%" }} >
                        <Box >
                            <span><b>Parent / Guardian:</b></span>
                        </Box>
                        <ArrayInput label="" source="Parents" validate={required()}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <Box style={{ width: "100%" }}>
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }}
                                                            parse={phoneNumberFormat}
                                                            label="Contact Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>
                                                    <Box >
                                                        <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <Box >
                                                        <span><b>Address if different from Child's home address:</b></span>
                                                    </Box>
                                                    <Box display="flex" >
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <span><b>Work Info:</b></span>
                                                </Box>
                                                <Box >
                                                    <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                    <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                    <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                    <Box >
                                                        <span><b>Company Address:</b></span>
                                                    </Box>
                                                    <Box display="flex">
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                    </AccordionDetails>
                    </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="Personal Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Personal Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box style={{ width: "100%" }} >

                        <Box >
                            <span><b>Child’s interests and favorite activities:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Characteristic Behavior (check all that apply):</b></span>
                            <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                                { id: 'shy', name: 'Shy' },
                                { id: 'calm', name: 'Calm' },
                                { id: 'cheerful', name: 'Cheerful' },
                                { id: 'aggressive', name: 'Aggressive' },
                                { id: 'whining', name: 'Whining' },
                                { id: 'friendly', name: 'Friendly' },
                                { id: 'excitable', name: 'Excitable' },
                                { id: 'fearful', name: 'Fearful' },
                                { id: 'cooperative', name: 'Cooperative' }
                            ]} validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Any fears? (History and manifestations):</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                        </Box>
                        <Box >
                            <span><b>Types of home discipline most frequently used:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Emergency Contact</b></span>
                            </Box>
                            <Box>
                                <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <ArrayInput label="" source="EmergContacts" validate={required()}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>

                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                            </Box>
                            <Box>
                                <b>Who will be the main adult to drop-off/pick-up the child? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mr="4em">
                                    <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput
                                        style={{ width: 150 }} parse={phoneNumberFormat}
                                        label="Phone"
                                        source="MainAuth.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                            </Box>
                            <ArrayInput label="" source="Oauths" isRequired={false} >
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhoneOpt}
                                                        />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                    </AccordionDetails>
                    </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="Enrollment Program Information"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Enrollment Program Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <span><b>How did you hear about LAMS?</b></span>
                            <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                                { id: 'Adwebsite', name: 'Website' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                    { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                                ]} />
                                <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                            </Box>
                            <Box display="flex">

                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                    { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                                ]} />
                                <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                                <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                            </Box>
                            <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                                { id: 'Adfndrel', name: 'From a Friend/Relative' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                    { id: 'Adother', name: 'Other' }
                                ]} />
                                <TextInput label="Specify" source="Mkting.OtherDtls" />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                            <Box display="flex">
                                <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                    { id: '2', name: '2 Years' },
                                    { id: '3', name: '3 Years' },
                                    { id: 'other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify" source="Intent.Other" />
                            </Box>
                            <Box>
                                <span><b>When is the desired enrollment (start) date?</b></span><br></br>
                                <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={validateEnrollmentDate} disabled/>
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Program Selection: </b></span><br></br>
                            <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                { id: 'Toddler', name: 'Toddler Program' },
                                { id: 'Primary', name: 'Primary Program' }

                            ]} onChange={handlePrgSel} validate={required()} disabled/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => primaryPrg &&
                                    <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                        { id: 'N', name: 'N' },
                                        { id: 'Y', name: 'Y' },
                                        // { id: 'T', name: 'InTraining' }
                                    ]} {...rest} validate={required()} disabled/> 
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                        <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                            </FormDataConsumer>
                            <br></br>
                            <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                                { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                            ]} validate={required()} disabled />
                        </Box>
                        <Box>
                        <span><b>Approval Acceptance: </b></span><br></br>
                        <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                        <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                            { id: 'Y', name: 'Y' },
                                            { id: 'N', name: 'N' },
                                        ]} validate={required()}/>
                        </Box>
                        <Box >
                            <span><b>Does your child have previous school experience?</b></span>
                            <Box display="flex">
                                <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                                <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                                <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                                <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />

                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                        </Box>
                        <Box >
                            <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                        </Box>
                    </Box>
                    </AccordionDetails>
                    </Accordion>
                {/* </FormTab> */}
                {/* <FormTab label="General Release Form"> */}
                <Accordion sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>General Release Form</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                                <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                    { id: 'Y', name: 'Yes' },
                                    { id: 'N', name: 'No' }

                                ]} validate={required()} />
                                <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                                <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                                <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                                <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                                <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                                <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                                <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                                <li>We understand balances due must be paid before evaluations can be released.</li>
                                <li>Fee once paid is not refundable under any circumstances.</li>
                                <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                            </ol>
                        </Box>
                        <Box>
                            <span><b>Parent’s/Guardian’s Approval</b></span>
                            <Box>
                                <span><b>Parent/Guardian #1</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span>Signature:</span>
                                    <DisplaySignature disabled label="Signature" source="GenRelease.PApp1.Signature" />
                                </Box>
                            </Box>
                            <Box>
                                <span><b>Parent/Guardian #2</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span>Signature:</span>
                                    <DisplaySignature disabled label="Signature" source="GenRelease.PApp2.Signature" />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    </AccordionDetails>
                    </Accordion>
                </FormTab>
            </TabbedForm >
            ) : (
<TabbedForm>
                <FormTab label="Child's Information" >
                    <Box style={{ width: "100%" }} >
                        <Box display="flex">
                            <Box>
                                <TextInput label="Child's Name" source="Child.Name" validate={required()} />
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Nick Name (if any)" source="Child.Nick" />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box>
                                <DateInput label="Child’s Date of Birth" source="Child.Dob" validate={validateDob} disabled/>
                            </Box>
                            <Box ml="2em">
                                <TextInput label="Child’s Place of Birth" source="Child.Pob" validate={required()} />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box >
                                <SelectInput label="Child’s Gender" source="Child.Sex" validate={validateSex} choices={[
                                    { id: 'M', name: 'M' },
                                    { id: 'F', name: 'F' },
                                    { id: 'O', name: 'Other' }
                                ]} />
                            </Box>
                            <Box ml="2em" >
                                <TextInput
                                    parse={phoneNumberFormat}
                                    label="Child's Contact Phone"
                                    source="Child.Phone"
                                    validate={validatePhone}
                                />
                            </Box>
                        </Box>
                        <Box >
                            <Box>
                                <Box>
                                    <b>Child’s Home Address </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.HomeAddr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Apt #" source="Child.HomeAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.HomeAddr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.HomeAddr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.HomeAddr.Zip" validate={validateZip} />
                                </Box>
                            </Box>
                            <Box >
                                <Box>
                                    <b>Child’s Mailing Address, if different from above </b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.MailingAddr.Street" /><MyTab />
                                    <TextInput label="Apt #" source="Child.MailingAddr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.MailingAddr.City" /><MyTab />
                                    <TextInput label="State" source="Child.MailingAddr.State" validate={validateStateOpt} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.MailingAddr.Zip" validate={validateZipOpt} />
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Child's Siblings? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Is Currently Enrolled at LAMS? </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="Siblings">
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Enrolled At LAMS" source={getSource('Enrolled')} choices={[
                                                                { id: 'Y', name: 'Y' },
                                                                { id: 'N', name: 'N' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                            <Box>
                                <Box>
                                    <b>Whom does the child live with and the household members (parents, siblings, relatives, etc.)? </b>
                                </Box>
                                <Box display="flex">
                                    <Box ml="5em" mr="12em">
                                        <b>First and Last Name </b>
                                    </Box>
                                    <Box mr="7em">
                                        <b>Relationship to Child</b>
                                    </Box>
                                    <Box mr="6em">
                                        <b>Age </b>
                                    </Box>
                                    <Box mr="4em">
                                        <b>Gender </b>
                                    </Box>
                                </Box>
                                <ArrayInput label="" source="HHmembers" validate={required()}>
                                    <SimpleFormIterator>
                                        <FormDataConsumer>
                                            {({ getSource, scopedFormData }) => {
                                                return (
                                                    <Box display="flex">
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 200 }} label="Relationship #" source={getSource('Relation')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <TextInput style={{ width: 50 }} label="Age" source={getSource('Age')} validate={required()} />
                                                        </Box>
                                                        <Box mr="4em">
                                                            <RadioButtonGroupInput style={{ width: 200 }} label="Gender" source={getSource('Sex')} choices={[
                                                                { id: 'M', name: 'M' },
                                                                { id: 'F', name: 'F' },
                                                            ]} validate={required()} />
                                                        </Box>
                                                    </Box>
                                                );
                                            }}
                                        </FormDataConsumer>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b>What languages does the child speak at home? </b>
                            </Box>
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: 300 }} label="" source="Child.Langs.Language" choices={[
                                    { id: 'English', name: 'English' },
                                    { id: 'Spanish', name: 'Spanish' },
                                    { id: 'Other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify Other Language" source="Child.Langs.Otherlang" />
                            </Box>
                        </Box>
                        <Box >
                            <Box >
                                <b><u>Child’s Doctor:</u></b>
                            </Box>
                            <Box>
                                <Box>
                                    <TextInput label="Doctor’s Name" source="Child.Dr.Name" validate={required()} />
                                </Box>
                                <Box>
                                    <TextInput label="Name of Practice" source="Child.Dr.Practice" validate={required()} />
                                </Box>
                                <Box >
                                    <b><u>Address:</u></b>
                                </Box>
                                <Box display="flex">
                                    <TextInput style={{ width: 300 }} label="Street" source="Child.Dr.Addr.Street" validate={required()} /><MyTab />
                                    <TextInput label="Suite #" source="Child.Dr.Addr.AptorSuite" /><MyTab />
                                    <TextInput label="City" source="Child.Dr.Addr.City" validate={required()} /><MyTab />
                                    <TextInput label="State" source="Child.Dr.Addr.State" validate={validateState} /><MyTab />
                                    <TextInput label="Zipcode" source="Child.Dr.Addr.Zip" validate={validateZip} />
                                </Box>
                                <Box>
                                    <TextInput
                                        parse={phoneNumberFormat}
                                        label="Phone"
                                        source="Child.Dr.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>Describe below any medical conditions the child has (including food or medication allergies):</u></b></span>
                            <Box>
                                <TextInput style={{ width: 500 }} multiline label="Medical Conditions" source="Child.MedConds" validate={required()} />
                            </Box>
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="Parent/Guardian Information">
                    <Box style={{ width: "100%" }} >
                        <Box >
                            <span><b>Parent / Guardian:</b></span>
                        </Box>
                        <ArrayInput label="" source="Parents" validate={required()}>
                            <SimpleFormIterator>
                                <FormDataConsumer>
                                    {({ getSource, scopedFormData }) => {
                                        return (
                                            <Box style={{ width: "100%" }}>
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relation to Child" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }}
                                                            parse={phoneNumberFormat}
                                                            label="Contact Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>
                                                    <Box >
                                                        <TextInput style={{ width: 300 }} label="Email" source={getSource('Email')} validate={validateEmail} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <Box >
                                                        <span><b>Address if different from Child's home address:</b></span>
                                                    </Box>
                                                    <Box display="flex" >
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Addr.Street')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Apt #" source={getSource('Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Addr.City')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Addr.State')} validate={validateStateOpt} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Addr.Zip')} validate={validateZipOpt} />
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <span><b>Work Info:</b></span>
                                                </Box>
                                                <Box >
                                                    <TextInput label="Job Title" source={getSource('Work.JobTitle')} validate={required()} /><MyTab />
                                                    <TextInput label="Job Description" source={getSource('Work.JobDescription')} validate={required()} /><MyTab />
                                                    <TextInput label="Company Name" source={getSource('Work.Company.Name')} validate={required()} />
                                                    <Box >
                                                        <span><b>Company Address:</b></span>
                                                    </Box>
                                                    <Box display="flex">
                                                        <TextInput style={{ width: 300 }} label="Street" source={getSource('Work.Company.Addr.Street')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Suite #" source={getSource('Work.Company.Addr.AptorSuite')} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="City" source={getSource('Work.Company.Addr.City')} validate={required()} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="State" source={getSource('Work.Company.Addr.State')} validate={validateState} /><MyTab />
                                                        <TextInput style={{ width: 150 }} label="Zipcode" source={getSource('Work.Company.Addr.Zip')} validate={validateZip} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    }}
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </FormTab>
                <FormTab label="Personal Information">
                    <Box style={{ width: "100%" }} >

                        <Box >
                            <span><b>Child’s interests and favorite activities:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="Child's Activities" source="Child.Activities" validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Characteristic Behavior (check all that apply):</b></span>
                            <CheckboxGroupInput label="" source="Child.Behavior" choices={[
                                { id: 'shy', name: 'Shy' },
                                { id: 'calm', name: 'Calm' },
                                { id: 'cheerful', name: 'Cheerful' },
                                { id: 'aggressive', name: 'Aggressive' },
                                { id: 'whining', name: 'Whining' },
                                { id: 'friendly', name: 'Friendly' },
                                { id: 'excitable', name: 'Excitable' },
                                { id: 'fearful', name: 'Fearful' },
                                { id: 'cooperative', name: 'Cooperative' }
                            ]} validate={required()} />
                        </Box>
                        <Box >
                            <span><b>Any fears? (History and manifestations):</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Fears" />
                        </Box>
                        <Box >
                            <span><b>Types of home discipline most frequently used:</b></span><br></br>
                            <TextInput style={{ width: 500 }} multiline label="" source="Child.Discipline" validate={required()} />
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Emergency Contact</b></span>
                            </Box>
                            <Box>
                                <b>In case of Emergency, if neither parent can be reached, who can we contact? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <ArrayInput label="" source="EmergContacts" validate={required()}>
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} validate={required()} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhone}
                                                        />
                                                    </Box>

                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                        <Box>
                            <Box >
                                <span><b>Authorization to Drop-Off / Pick-Up Child</b></span>
                            </Box>
                            <Box>
                                <b>Who will be the main adult to drop-off/pick-up the child? </b>
                            </Box>
                            <Box display="flex">
                                <Box mr="14em">
                                    <b>First and Last Name </b>
                                </Box>
                                <Box mr="8em">
                                    <b>Relationship to Child</b>
                                </Box>
                                <Box mr="6em">
                                    <b>Contact Phone# </b>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mr="4em">
                                    <TextInput style={{ width: 300 }} label="First and Last Name" source="MainAuth.Name" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput style={{ width: 150 }} label="Relationship" source="MainAuth.Relation" validate={required()} />
                                </Box>
                                <Box mr="4em">
                                    <TextInput
                                        style={{ width: 150 }} parse={phoneNumberFormat}
                                        label="Phone"
                                        source="MainAuth.Phone"
                                        validate={validatePhone}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <b>Who are the other adults that have authorization to drop-off/pick-up the child? </b>
                            </Box>
                            <ArrayInput label="" source="Oauths" isRequired={false} >
                                <SimpleFormIterator>
                                    <FormDataConsumer>
                                        {({ getSource, scopedFormData }) => {
                                            return (
                                                <Box display="flex">
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 300 }} label="First and Last Name" source={getSource('Name')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput style={{ width: 150 }} label="Relationship" source={getSource('Relation')} />
                                                    </Box>
                                                    <Box mr="4em">
                                                        <TextInput
                                                            style={{ width: 150 }} parse={phoneNumberFormat}
                                                            label="Phone"
                                                            source={getSource('Phone')}
                                                            validate={validatePhoneOpt}
                                                        />
                                                    </Box>
                                                </Box>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="Enrollment Program Information">
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <span><b>How did you hear about LAMS?</b></span>
                            <CheckboxGroupInput label="" source="Mkting.Website" choices={[
                                { id: 'Adwebsite', name: 'Website' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.SE" choices={[
                                    { id: 'Adsearcheng', name: 'Online Search Engine (i.e., Google)' }
                                ]} />
                                <TextInput label="Specify Search Engine" source="Mkting.SEName" />
                            </Box>
                            <Box display="flex">

                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Referred" choices={[
                                    { id: 'Adrefer', name: 'Referred By a Parent/Student' }
                                ]} />
                                <TextInput label="First and Last Name" source="Mkting.ReferingPerson.Name" /><MyTab />
                                <TextInput label="Relation to Child" source="Mkting.ReferingPerson.Relation" />
                            </Box>
                            <CheckboxGroupInput label="" source="Mkting.FriendorRel" choices={[
                                { id: 'Adfndrel', name: 'From a Friend/Relative' }
                            ]} />
                            <Box display="flex">
                                <CheckboxGroupInput style={{ width: "200px" }} label="" source="Mkting.Other" choices={[
                                    { id: 'Adother', name: 'Other' }
                                ]} />
                                <TextInput label="Specify" source="Mkting.OtherDtls" />
                            </Box>
                        </Box>
                        <Box >
                            <span><b>LAMS strongly encourages a three-year program (including kindergarten) in order to provide the maximum advantage of the Montessori method. A two-year program is the minimum recommended enrollment. How long do you intend to enroll your child at LAMS?</b></span>
                            <Box display="flex">
                                <RadioButtonGroupInput style={{ width: "300px" }} label="" source="Intent.Duration" choices={[
                                    { id: '2', name: '2 Years' },
                                    { id: '3', name: '3 Years' },
                                    { id: 'other', name: 'Other' }
                                ]} validate={required()} />
                                <TextInput label="Specify" source="Intent.Other" />
                            </Box>
                            <Box>
                                <span><b>When is the desired enrollment (start) date?</b></span><br></br>
                                <DateInput label="Start date" source="Child.DesiredEnrollmentDate" validate={validateEnrollmentDate} disabled/>
                            </Box>
                        </Box>
                        <Box >
                            <span><b>Program Selection: </b></span><br></br>
                            <RadioButtonGroupInput label="" source="Prg.Name" choices={[
                                { id: 'Toddler', name: 'Toddler Program' },
                                { id: 'Primary', name: 'Primary Program' }

                            ]} onChange={handlePrgSel} validate={required()} disabled/>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => primaryPrg &&
                                    <SelectInput style={{ width: "250px" }} label="Child is Fully Potty Trained" source="Child.PottyTrained" choices={[
                                        { id: 'N', name: 'N' },
                                        { id: 'Y', name: 'Y' },
                                        // { id: 'T', name: 'InTraining' }
                                    ]} {...rest} validate={required()} disabled/> 
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                    {({ formData, ...rest }) => primaryPrg &&
                                        <span>
                                        <b>Child attending LAMS for primary program is required to be fully potty trained. For details of what fully potty trained means please refer <a target="_blank" href="https://lamsaz.com/formsAndCalendar/index.html#potty_trg_req">here</a>.</b>
                                        </span>
                                    }
                            </FormDataConsumer>
                            <br></br>
                            <RadioButtonGroupInput label="" source="Prg.Type" choices={[
                                { id: 'HalfDay', name: 'Half Day 8:05 AM - 12:30' },
                                { id: 'FullDay', name: 'Full Day 8:05 AM - 3:00 PM' },
                                { id: 'ExtendedDay', name: 'Extended Day 7:30 AM - 5:00 PM' }
                            ]} validate={required()} disabled />
                        </Box>
                        <Box>
                        <span><b>Approval Acceptance: </b></span><br></br>
                        <span>If and when approved, is a tour of the school a precondition for your acceptance of the enrollment?</span><br></br>
                        <SelectInput style={{ width: "250px" }} label="Approval Acceptance" source="TourPrecondition" choices={[
                                            { id: 'Y', name: 'Y' },
                                            { id: 'N', name: 'N' },
                                        ]} validate={required()}/>
                        </Box>
                        <Box >
                            <span><b>Does your child have previous school experience?</b></span>
                            <Box display="flex">
                                <TextInput label="Name of school" source="Child.PrvSchool.Name" /><MyTab />
                                <TextInput label="Address" source="Child.PrvSchool.Addr" /><MyTab />
                                <DateInput label="Attendance From date" source="Child.PrvSchool.FrmDt" /><MyTab />
                                <DateInput label="Attendance To date" source="Child.PrvSchool.ToDt" />

                            </Box>
                        </Box>
                        <Box >
                            <span><b><u>What do you hope a Montessori education will do for your child:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="HopeFrmMontessoriEdu" validate={required()} />
                        </Box>
                        <Box >
                            <span><b><u>Describe briefly the background of your interest in our Montessori program:</u></b></span><br></br>
                            <TextInput style={{ width: "500px" }} multiline label="" source="BackgrdOfMontessori" validate={required()} />
                        </Box>
                    </Box>
                </FormTab>
                <FormTab label="General Release Form">
                    <Box style={{ width: "100%" }}>
                        <Box >
                            <ol><li>LAMS may use photographs, reproductions, video recordings, and/or sound recordings of my child(ren). Such use may include advertising and publicity purposes.</li>
                                <RadioButtonGroupInput label="" source="GenRelease.UseofMedia" choices={[
                                    { id: 'Y', name: 'Yes' },
                                    { id: 'N', name: 'No' }

                                ]} validate={required()} />
                                <li>I hereby release, indemnify and hold harmless LAMS and its staff from any loss or damage to toys clothes, or any other personal articles.</li>
                                <li>I hereby warrant to LAMS that I am entitled to legal custody and possession of my child(ren) and, accordingly, am authorized to place my child(ren) in your care and custody and am further authorized to sign this release form.</li>
                                <li>Tuition payments are to be made on the first school day of each month. All returned checks will be assessed a $50.00 returned check fee.</li>
                                <li>Please provide your child with a nutritious lunch. Morning and afternoon snacks will be provided by LAMS.</li>
                                <li>Parents are asked to provide a small pillow and blanket for nap time. Please be sure your child’s name is clearly marked on his or her belongings. Nap time bedding is to be taken home each Friday for laundering.</li>
                                <li>Children attending LAMS(for primary program) are required to be fully potty trained.</li>
                                <li>We understand that expenses continue whether or not our child is present at school each day. We will be charged according to the fee schedule even if our child is absent. No tuition refund will be made in the case of absence for illness, vacation, or any other reason, as long as a place is being held for our child. Charges will continue until our child is officially withdrawn. A two-week notice will be provided prior to withdrawal.</li>
                                <li>We understand balances due must be paid before evaluations can be released.</li>
                                <li>Fee once paid is not refundable under any circumstances.</li>
                                <li>This document is understood to be a binding contract, and we have read and agree to comply with the above commitment.</li>
                            </ol>
                        </Box>
                        <Box>
                            <span><b>Parent’s/Guardian’s Approval</b></span>
                            <Box>
                                <span><b>Parent/Guardian #1</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp1.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp1.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp1.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span>Signature:</span>
                                    <DisplaySignature disabled label="Signature" source="GenRelease.PApp1.Signature" />
                                </Box>
                            </Box>
                            <Box>
                                <span><b>Parent/Guardian #2</b></span><br></br>
                                <TextInput label="Name" source="GenRelease.PApp2.Name" validate={required()} /><MyTab />
                                <TextInput label="Relationship" source="GenRelease.PApp2.Relation" validate={required()} /><MyTab />
                                <DateInput label="Date" source="GenRelease.PApp2.Date" validate={required()} />
                                <Box style={{ width: "100%" }}>
                                    <span>Signature:</span>
                                    <DisplaySignature disabled label="Signature" source="GenRelease.PApp2.Signature" />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </FormTab>
            </TabbedForm >
            )
        }
        </Edit>
    );
}

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const LamsApplicationListActions = props => (
    <TopToolbar>
    </TopToolbar>
);
